<template>
  <v-content>
    <router-view :liveFeed="liveFeed" :fetchedOrLiveUser="fetchedOrLiveUser" />

    <dashboard-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',
    props: ['liveFeed', 'fetchedOrLiveUser'],
    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>
